<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import ModalServiceComponent from "@/components/modalServiceComponent.vue";
import btnComponent from "@/components/btnComponent";
// import ChangeDate from "@/components/changeDateComponent";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ประวัติการเข้ารับบริการ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    // ModalServiceComponent,
    Multiselect,
    btnComponent,
    // ChangeDate
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      isLoading: false,
      loading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      title: "ประวัติการเข้ารับบริการ",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ประวัติการเข้ารับบริการ",
          active: true,
        },
      ],
      userBranch: "",
      apptId: "",
      rowsSelect: [],
      service: [],
      rowsAppointment: [],
      rowsAppointmentDetail: [],
      svId: "",
      hasGsJob: 0,
      hasBpJob: 0,
      hasWcJob: 0,
      apptDate: "",
      nameCus: "",
      vin: "",
      svDate: "",
      type: "",
      disTance: "",
      insurance: "",
      warranty: "",
      requestType: "",
      licensePlate: "",
      // vin: "",
      typeOptions: [
        {
          id: 1,
          typeId: 1,
          name: "GS",
        },
        {
          id: 2,
          typeId: 1,
          name: "BP",
        },
        {
          id: 3,
          typeId: 1,
          name: "Warranty",
        },
      ],
      requestTypeOptions: [
        {
          id: 1,
          name: "PRE",
        },
      ],
      fields: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        // {
        //   key: "familyNameThArr",
        //   sortable: true,
        //   label: "นามสกุลลูกค้า",
        // },
      ],
      distanceOptions: [
        {
          id: 1,
          name: "เช็คระยะ 10,000 กิโล",
        },
        {
          id: 2,
          name: "เช็คระยะ 20,000 กิโล",
        },
        {
          id: 3,
          name: "เช็คระยะ 30,000 กิโล",
        },
      ],
      roCode: "",
      arrRoArr: [],
      svlength: "",
      driverBuildingNumber: "",
      driverTownshipNumber: "",
      driverTownshipName: "",
      driverSubLane: "",
      driverLane: "",
      driverStreetName: "",
      driverDistrictNameTh: "",
      driverAmphurNameTh: "",
      driverProvinceNameTh: "",
      driverPostCode: "",
      driverNameTh: "",
      driverMobilePhone: "",
      driverEmail: "",
      driverCitizenId: "",
      driverBirthDate: "",
      driverCusType: "",
      driverNote: "",
      branchIdSv: "",
      ownerBuildingNumber: "",
      ownerTownshipNumber: "",
      ownerTownshipName: "",
      ownerSubLane: "",
      ownerLane: "",
      ownerStreetName: "",
      ownerDistrictNameTh: "",
      ownerAmphurNameTh: "",
      ownerProvinceNameTh: "",
      ownerPostCode: "",
      ownerNameTh: "",
      ownerMobilePhone: "",
      ownerEmail: "",
      ownerCitizenId: "",
      ownerBirthDate: "",
      ownerCusType: "",
      ownerNote: "",
      roWageDetail: [],
      roPartDetail: [],
      exteriorColor: "",
      cc: "",
      modelYear: "",
      mileNumber: "",
      nameThArr: "",
      fullAddress: "",
      prefixId: "",
      citizenId: "",
      email: "",
      typeId: "",
      gearSystemId: "",
      chassisNumber: "",
      engineNumber: "",
      localData: [],
      fullNameThArr: "",
      chk: "",
      selected: [],
      selectedAppointment: "",
      submitform: false,
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      currentPageAppointment: 1,
      perPage: 10,
      perPageAppointment: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      filter: {
        licensePlate: "",
        mobilePhone: "",
        branch: "",
      },
      dataPrint: "",
      arrRo: [],
      dataHisArr: [],
      nameTh: "",
      filterOn: [],
      rows: [],
      rowsService: [],
      rowsUser: [],
      rowsCusfull: "",
      rowsSearch: "",
      sortBy: "age",
      sortDesc: false,
      selectMode: "single",
      roDetails: [],
      check: false,
      svDates: "",
      vins: "",
      mobilePhone: "",
      familyNameTh: "",
      licensePlates: "",
      dataHis: [],
      overlayFlag: false,
      rowsMasterSD: [],
      distance: "",
      expectFinishDate: "",
      expectFinishTime: "",
      mobilePhoneArr: "",
      vinArr: "",
      platePrefixNumber: "",
      plateNumber: "",
      plateProvinceId: "",
      name: "",
      familyName: "",
      rowsProvince: [],
      rowsPlate: [],
      optionBranch: [],
      rowsVehicle: [],
    };
  },
  validations: {
    licensePlate: {
      required,
    },
    svDate: {
      required,
    },
    distance: {
      required,
    },
  },
  computed: {
    data: () => {
      return this.data.filter(function (item) {
        return item != null;
      });
    },
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
    this.totalRows = this.rows.total;
    this.totalRows = this.rowsAppointment.total;
  },
  created() {
    this.getDataVehicle();
    this.getLocalData();
    // this.getDataHis();
  },
  methods: {
    toLocaleStringSupportsLocales() {
      var number = 0;
      try {
        number.toLocaleString("i");
      } catch (e) {
        return e.name === "RangeError";
      }
      return false;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    checkNull(data) {
      return data ? data : "-";
    },
    checkIsbooking(booking) {
      if (booking == 1) {
        return "ใช่";
      } else if (booking == 0) {
        return "ไม่ใช่";
      } else {
        return "";
      }
    },
    checkIswash(wash) {
      if (wash == 1) {
        return "ล้าง";
      } else if (wash == 0) {
        return "ไม่ล้าง";
      } else {
        return "";
      }
    },
    checkIswaiting(waiting) {
      if (waiting == 1) {
        return "รอ";
      } else if (waiting == 0) {
        return "ไม่รอ";
      } else {
        return "";
      }
    },
    typeVehicle(id) {
      if (id == 1) {
        return "เก๋ง";
      } else if (id == 2) {
        return "กระบะตอนเดียว";
      } else if (id == 3) {
        return "กระบะสองตอน";
      } else if (id == 4) {
        return "SUV";
      } else {
        return "-";
      }
    },
    checkGear(id) {
      if (id == 1) {
        return "เกียร์ธรรมดา";
      } else if (id == 2) {
        return "เกียร์อัตโนมัติ";
      } else if (id == 3) {
        return "เกียร์ CVT";
      } else if (id == 4) {
        return "เกียร์กึ่งอัตโนมัติ และ เกียร์คลัตช์คู่ DCT";
      } else {
        return "-";
      }
    },
    checkColer(id) {
      if (id == 1) {
        return "Soul Red Crystal";
      } else if (id == 2) {
        return "Soul Red Crystal";
      } else {
        return "-";
      }
    },
    checkTypeCus(type) {
      if (type == "P") {
        return "บุคคล";
      } else if (type == "B") {
        return "นิติบุคคล";
      } else {
        return "-";
      }
    },
    checkType(chassis) {
      let checkNullssss;
      if (chassis === null) {
        checkNullssss = "ไม่ระบุ";
      } else {
        checkNullssss = chassis;
      }
      return checkNullssss;
    },
    change(dateChange) {
      const today = new Date(dateChange);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear() + 543;
      const date = `${dd}/${mm}/${yyyy}`;
      const changeDate = date;
      return changeDate;
    },
    changeDate(dateChange) {
      const today = new Date(dateChange);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear() + 543;

      const date = `${dd}/${mm}/${yyyy}`;
      const changeDate = date;
      return changeDate;
    },
    getPrint: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/service-history/print", {
          params: {
            branchId:
              this.filter.branch.branchId != null
                ? this.filter.branch.branchId
                : this.branchId,
            licensePlate: this.licensePlate.licensePlate,
          },
        })
        .then((response) => {
          this.dataPrint = response.data.url;
          window.location = this.dataPrint;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataHis: function () {
      this.overlayFlag = true;
      this.isLoading = true;
      useNetw
        .get("api/service-history", {
          params: {
            licensePlate:
              this.licensePlate.licensePlate != null
                ? this.licensePlate.licensePlate
                : "",
            branchId: this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.dataHis = response.data.data;
          this.dataHisArr = response.data.data;

          if (this.dataHis.length != 0) {
            const svArr = [];

            this.dataHis.forEach((item) => {
              svArr.push(item.svCode);
              this.arrRo = item.roLists;
              this.arrRo.forEach((i) => {
                this.roPartDetail = i.roPart;
                this.roWageDetail = i.roWage;
              });
            });

            var unique = svArr.filter(this.onlyUnique);
            this.svlength = unique;

            this.ownerNameTh = response.data.master.ownerNameTh;
            this.ownerMobilePhone = response.data.master.ownerMobilePhone;
            this.ownerEmail = response.data.master.ownerEmail;
            this.ownerCitizenId = response.data.master.ownerCitizenId;
            this.ownerBirthDate = response.data.master.ownerBirthDate;
            this.ownerCusType = response.data.master.ownerCusType;
            this.ownerNote = response.data.master.ownerNote;

            this.ownerBuildingNumber = response.data.master.ownerBuildingNumber;
            this.ownerTownshipNumber = response.data.master.ownerTownshipNumber;
            this.ownerTownshipName = response.data.master.ownerTownshipName;
            this.ownerSubLane = response.data.master.ownerSubLane;
            this.ownerLane = response.data.master.ownerLane;
            this.ownerStreetName = response.data.master.ownerStreetName;
            this.ownerDistrictNameTh = response.data.master.ownerDistrictNameTh;
            this.ownerAmphurNameTh = response.data.master.ownerAmphurNameTh;
            this.ownerProvinceNameTh = response.data.master.ownerProvinceNameTh;
            this.ownerPostCode = response.data.master.ownerPostCode;
            // driver
            this.driverNameTh = response.data.master.driverNameTh;
            this.driverMobilePhone = response.data.master.driverMobilePhone;
            this.driverEmail = response.data.master.driverEmail;
            this.driverCitizenId = response.data.master.driverCitizenId;
            this.driverBirthDate = response.data.master.driverBirthDate;
            this.driverCusType = response.data.master.driverCusType;
            this.driverNote = response.data.master.driverNote;
            this.driverBuildingNumber =
              response.data.master.driverBuildingNumber;
            this.driverTownshipNumber =
              response.data.master.driverTownshipNumber;
            this.driverTownshipName = response.data.master.driverTownshipName;
            this.driverSubLane = response.data.master.driverSubLane;
            this.driverLane = response.data.master.driverLane;
            this.driverStreetName = response.data.master.driverStreetName;
            this.driverDistrictNameTh =
              response.data.master.driverDistrictNameTh;
            this.driverAmphurNameTh = response.data.master.driverAmphurNameTh;
            this.driverProvinceNameTh =
              response.data.master.driverProvinceNameTh;
            this.driverPostCode = response.data.master.driverPostCode;

            this.vehicleModelTh = response.data.master.vehicleModelTh; //รุ่น
            this.vehicleBrandTh = response.data.master.vehicleBrandTh; // ยี่ห้อ
            this.chassisNumber = response.data.master.chassis_number;
            this.engineNumber = response.data.master.engine_number;
            this.gearSystemId = response.data.master.gear_system_id;
            this.typeId = response.data.master.typeId;
            this.citizenId = response.data.master.citizenId;
            this.email = response.data.master.email;
            this.prefixId = response.data.master.prefixId;
            this.mileNumber = response.data.master.mile_number;
            this.modelYear = response.data.master.model_year;
            this.cc = response.data.master.cc;
            this.exteriorColor = response.data.master.exterior_color_id;
            this.licensePlates = response.data.master.license_plate;
            this.vin = response.data.master.vin;

            this.check = true;
          }
        })
        .catch((err) => {
          if (err.response.data.length == 0) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.validationMessage),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },

    getDataVehicle: function (event) {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.localDataBranchIdUser,
            licensePlate: event,
          },
        })
        .then((response) => {
          this.rowsVehicle = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),

              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    deleteVehicle(rows) {
      this.vehicleId = rows;
      this.overlayFlag = true;
      useNetw
        .delete("api/vehicle/delete", {
          data: {
            vehicleId: this.vehicleId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      if (this.filter.branch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [
          this.filter.branch ? this.filter.branch.branchId : this.branchId,
        ];
      }
      if (this.licensePlate != "") {
        this.getDataHis();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณาระบุเลขทะเบียน",
          appConfig.swal.type.error
        );
      }
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
.style-td {
  width: 100px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>สาขา:</label>
                        <multiselect
                          :options="localData"
                          v-model="filter.branch"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          :show-labels="false"
                          label="nameTh"
                          placeholder=""
                        >
                          <!-- <span slot="noResult">ไม่พบข้อมูล</span> -->
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code><label>เลขทะเบียน:</label>
                        <multiselect
                          v-model="licensePlate"
                          :options="rowsVehicle"
                          :close-on-select="true"
                          :internal-search="true"
                          open-direction="bottom"
                          :show-labels="false"
                          label="licensePlate"
                          placeholder=""
                          @search-change="getDataVehicle($event)"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-sm-3 col-md-6 text-end">
                      <btnComponent
                        @click="handleSearch()"
                        changeStyle="search"
                      >
                      </btnComponent
                      >&nbsp;&nbsp;&nbsp;&nbsp;

                      <router-link
                        :to="{ name: 'servicelist' }"
                        class="btn btn-primary"
                      >
                        <i class="mdi mdi-plus me-1"></i>การเข้ารับบริการ
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <span
                  v-if="this.dataHis.length === 0"
                  class="text-center font-size-16 mt-4"
                  >ไม่พบรายการ</span
                >
                <div
                  class="col-12"
                  v-if="this.dataHis.length !== 0 && this.check == true"
                >
                  <div class="row pt-4">
                    <div class="col-6 col-lg-6 col-md-6">
                      <div>
                        <span class="font-size-20 float-left"
                          ><b>Service</b></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-lg-6 col-md-6 pb-2">
                      <div>
                        <span class="font-size-20 float-left">ศูนย์บริการ</span>
                      </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6 pb-2 text-end">
                      <b-button variant="danger" @click="getPrint()"
                        ><i class="uil-print"></i>พิมพ์รายงาน
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="card"
                v-if="this.dataHis.length !== 0 && this.check == true"
              >
                <!-- dripicons-user-id -->
                <div class="row m-2">
                  <div class="col-md-7 col-sm-6 m-2">
                    <div class="text">
                      <h6><b>ข้อมูลเจ้าของรถ</b></h6>
                      <h5 class="font-size-15 mb-2">
                        &nbsp;คุณ{{ this.ownerNameTh }}
                      </h5>
                      <p class="mb-1">
                        &nbsp;เบอร์โทรศัพท์:
                        {{ checkNull(this.ownerMobilePhone) }}
                      </p>
                      <p class="mb-1">
                        &nbsp;อีเมล์:
                        {{ checkNull(this.ownerEmail) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;เลขบัตรประชาชน:
                        {{ checkNull(this.ownerCitizenId) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;วันเกิด:
                        {{ change(this.ownerBirthDate) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;ประเภทบุคคล:
                        {{ checkTypeCus(this.ownerCusType) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;หมายเหตุ:
                        {{ checkNull(this.ownerNote) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;ที่อยู่:
                        {{
                          this.ownerBuildingNumber != "" &&
                          this.ownerBuildingNumber != null
                            ? "" + this.ownerBuildingNumber
                            : ""
                        }}
                        {{
                          this.ownerTownshipNumber != "" &&
                          this.ownerTownshipNumber != null
                            ? "หมู่" + " " + this.ownerTownshipNumber
                            : ""
                        }}
                        {{
                          this.ownerTownshipName != "" &&
                          this.ownerTownshipName != "undefined" &&
                          this.ownerTownshipName != null
                            ? "" + this.ownerTownshipName
                            : ""
                        }}
                        {{
                          this.ownerSubLane != "" &&
                          this.ownerSubLane != "undefined" &&
                          this.ownerSubLane != undefined
                            ? "ตรอก" + " " + this.ownerSubLane
                            : ""
                        }}
                        {{
                          this.ownerLane != "" && this.ownerLane != null
                            ? "ซอย" + " " + this.ownerLane
                            : ""
                        }}
                        {{
                          this.ownerStreetName != "" &&
                          this.ownerStreetName != null
                            ? "ถนน" + " " + this.ownerStreetName
                            : ""
                        }}
                        {{
                          this.ownerDistrictNameTh != "" &&
                          this.ownerDistrictNameTh != undefined &&
                          this.ownerDistrictNameTh != null
                            ? "ตำบล" + this.ownerDistrictNameTh
                            : ""
                        }}
                        {{
                          this.ownerAmphurNameTh != "" &&
                          this.ownerAmphurNameTh != undefined &&
                          this.ownerAmphurNameTh != null
                            ? "อำเภอ" + this.ownerAmphurNameTh
                            : ""
                        }}
                        {{
                          this.ownerProvinceNameTh != "" &&
                          this.ownerProvinceNameTh != null
                            ? "จังหวัด" + this.ownerProvinceNameTh
                            : ""
                        }}
                        {{
                          this.ownerPostCode != "" && this.ownerPostCode != null
                            ? "รหัสไปรษณีย์" + " " + this.ownerPostCode
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="text">
                      <h6><b>ข้อมูลผู้ขับขี่</b></h6>
                      <h5 class="font-size-15 mb-2">
                        &nbsp;คุณ{{ this.driverNameTh }}
                      </h5>
                      <p class="mb-1">
                        &nbsp;เบอร์โทรศัพท์:
                        {{ checkNull(this.driverMobilePhone) }}
                      </p>
                      <p class="mb-1">
                        &nbsp;อีเมล์:
                        {{ checkNull(this.driverEmail) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;เลขบัตรประชาชน:
                        {{ checkNull(this.driverCitizenId) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;วันเกิด:
                        {{ change(this.driverBirthDate) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;ประเภทบุคคล:
                        {{ checkTypeCus(this.driverCusType) }}
                      </p>
                      <p class="mb-2">
                        &nbsp;หมายเหตุ:
                        {{ checkNull(this.driverNote) }}
                      </p>
                      <p class="mb-2">
                        &nbsp; ที่อยู่:
                        {{
                          this.driverBuildingNumber != "" &&
                          this.driverBuildingNumber != null
                            ? "" + this.driverBuildingNumber
                            : ""
                        }}
                        {{
                          this.driverTownshipNumber != "" &&
                          this.driverTownshipNumber != null
                            ? "หมู่" + " " + this.driverTownshipNumber
                            : ""
                        }}
                        {{
                          this.driverTownshipName != "" &&
                          this.driverTownshipName != "undefined" &&
                          this.driverTownshipName != null
                            ? "" + this.driverTownshipName
                            : ""
                        }}
                        {{
                          this.driverSubLane != "" &&
                          this.driverSubLane != "undefined" &&
                          this.driverSubLane != undefined
                            ? "ตรอก" + " " + this.driverSubLane
                            : ""
                        }}
                        {{
                          this.driverLane != "" && this.driverLane != null
                            ? "ซอย" + " " + this.driverLane
                            : ""
                        }}
                        {{
                          this.driverStreetName != "" &&
                          this.driverStreetName != null
                            ? "ถนน" + " " + this.driverStreetName
                            : ""
                        }}
                        {{
                          this.driverDistrictNameTh != "" &&
                          this.driverDistrictNameTh != undefined &&
                          this.driverDistrictNameTh != null
                            ? "ตำบล" + this.driverDistrictNameTh
                            : ""
                        }}
                        {{
                          this.driverAmphurNameTh != "" &&
                          this.driverAmphurNameTh != undefined &&
                          this.driverAmphurNameTh != null
                            ? "อำเภอ" + this.driverAmphurNameTh
                            : ""
                        }}
                        {{
                          this.driverProvinceNameTh != "" &&
                          this.driverProvinceNameTh != null
                            ? "จังหวัด" + this.driverProvinceNameTh
                            : ""
                        }}
                        {{
                          this.driverPostCode != "" &&
                          this.driverPostCode != null
                            ? "รหัสไปรษณีย์" + " " + this.driverPostCode
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <hr class="my-2" />
                <!-- <div class="row m-2"> -->
                <div class="col-md-12 col-sm-6 m-2">
                  <div class="row m-2">
                    <h6><b>ข้อมูลรถ</b></h6>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;ทะเบียนรถ:
                      {{ checkNull(this.licensePlates) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;เลขตัวถัง: {{ this.vin }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;เลขตัวถัง: {{ checkNull(this.chassisNumber) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;เลขตัวเครื่อง: {{ checkNull(this.engineNumber) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;ยี่ห้อ: {{ checkNull(this.vehicleBrandTh) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;รุ่น: {{ checkNull(this.vehicleModelTh) }}({{
                        checkNull(this.modelYear)
                      }})
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;เกียร์: {{ checkGear(this.gearSystemId) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;ประเภทรถ: {{ typeVehicle(this.typeId) }}
                    </div>

                    <div class="col-md-3 col-sm-6">&nbsp;CC: {{ this.cc }}</div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;สีภายนอก: {{ checkColer(this.exteriorColor) }}
                    </div>
                    <div class="col-md-3 col-sm-6">
                      &nbsp;เลขไมล์: {{ checkNull(this.mileNumber) }}
                    </div>
                  </div>
                </div>

                <!-- </div> -->
              </div>
              <hr />

              <div
                class="row m-4"
                v-if="this.dataHis.length !== 0 && this.check == true"
              >
                <b
                  >รายการการเข้ารับบริการ
                  <span class="badge bg-info font-size-12 ms-2 pt-2"
                    >({{ this.svlength.length }} ครั้ง)
                  </span></b
                >
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-bordered">
                  <tbody v-for="(data, index) in dataHisArr" :key="'A' + index">
                    <tr>
                      <th class="table-active" colspan="3">
                        {{ data.svCode }}
                      </th>
                    </tr>

                    <tr>
                      <!-- <td></td> -->
                      <td colspan="3">
                        ประเภท
                        {{
                          checkType(data.distanceNameTh)
                        }}&nbsp;&nbsp;&nbsp;เข้ารับบริการวันที่
                        {{ change(data.svDate) }}
                      </td>
                    </tr>

                    <tr
                      v-for="(item, index) in data.roLists"
                      :key="'B' + index"
                    >
                      <td></td>
                      <td class="p-0" colspan="2">
                        <table class="table table-bordered mb-0">
                          <thead>
                            <tr class="table-active">
                              <th>{{ item.roCode }}</th>
                              <td>
                                แจ้งซ่อมวันที่:
                                {{ change(item.roDate) }}
                                &nbsp;&nbsp;&nbsp;&nbsp;ลูกค้านัดหมาย:&nbsp;&nbsp;{{
                                  checkIsbooking(item.isBooking)
                                }}

                                ล้างรถ:&nbsp;&nbsp;{{
                                  checkIswash(item.isWash)
                                }}
                                สถานะ:&nbsp;&nbsp;{{
                                  checkIswaiting(item.isWaiting)
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>เลขไมล์:&nbsp;&nbsp;{{ item.mileNo }}</td>
                              <td>หมายเหตุ:&nbsp;&nbsp;{{ item.node }}</td>
                            </tr>
                          </thead>

                          <tbody>
                            <!-- อะไหล่ -->
                            <tr
                              v-for="(part, index) in item.roPart"
                              :key="'P' + index"
                            >
                              <td
                                v-if="item.roPart.length > 0"
                                class="p-0"
                                colspan="2"
                              >
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <td class="style-td"><b>ค่าอะไหล่</b></td>
                                      <td class="style-td">
                                        <b>ราคา/หน่วย</b>
                                      </td>
                                      <td class="style-td"><b>ราคารวม</b></td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td">
                                        <b>รวมค่าอะไหล่</b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="style-td">
                                        <li>
                                          {{ part.nameTh
                                          }}<b
                                            >&nbsp;X&nbsp;{{ part.amount }}</b
                                          >
                                        </li>
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            part.pricePerOne
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            part.totalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{ part.dcPer }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(part.netPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.partNetPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </td>
                            </tr>
                            <!-- ค่าแรง -->

                            <tr
                              v-for="(wage, index) in item.roWage"
                              :key="'W' + index"
                            >
                              <td
                                v-if="item.roWage.length > 0"
                                class="p-0"
                                colspan="2"
                              >
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <td class="style-td"><b>ค่าแรง</b></td>
                                      <td class="style-td">
                                        <b>ราคา/หน่วย</b>
                                      </td>
                                      <td class="style-td"><b>ราคารวม</b></td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td"><b>รวมค่าแรง</b></td>
                                    </tr>
                                    <tr>
                                      <td class="style-td">
                                        <li>
                                          {{ wage.nameTh
                                          }}<b
                                            >&nbsp;X&nbsp;{{ wage.amount }}</b
                                          >
                                        </li>
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            wage.pricePerOne
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            wage.totalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{ wage.dcPer }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(wage.netPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.wageNetPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </td>
                            </tr>
                            <!-- ค่าของเหลว -->

                            <tr
                              v-for="(liquid, index) in item.roLiquid"
                              :key="'L' + index"
                            >
                              <td
                                v-if="item.roLiquid.length > 0"
                                class="p-0"
                                colspan="2"
                              >
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <td class="style-td">
                                        <b>ค่าของเหลว</b>
                                      </td>
                                      <td class="style-td">
                                        <b>ราคา/หน่วย</b>
                                      </td>
                                      <td class="style-td"><b>ราคารวม</b></td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td">
                                        <b>รวมค่าของเหลว</b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="style-td">
                                        <li>
                                          {{ liquid.nameTh
                                          }}<b
                                            >&nbsp;X&nbsp;{{ liquid.amount }}</b
                                          >
                                        </li>
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            liquid.pricePerOne
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            liquid.totalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{ liquid.dcPer }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            liquid.netPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.liquidNetPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </td>
                            </tr>
                            <!-- ค่างานนอก -->

                            <tr
                              v-for="(os, index) in item.roOs"
                              :key="'O' + index"
                            >
                              <td
                                v-if="item.roOs.length > 0"
                                class="p-0"
                                colspan="2"
                              >
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <td class="style-td"><b>ค่างานนอก</b></td>
                                      <td class="style-td">
                                        <b>ราคา/หน่วย</b>
                                      </td>
                                      <td class="style-td"><b>ราคารวม</b></td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td">
                                        <b>รวมค่างานนอก</b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="style-td">
                                        <li>
                                          {{ os.nameTh
                                          }}<b>&nbsp;X&nbsp;{{ os.amount }}</b>
                                        </li>
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            os.pricePerOne
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(os.totalPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{ os.dcPer }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(os.netPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(os.osNetPrice).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </td>
                            </tr>
                            <!-- ค่าอื่นๆ -->

                            <tr
                              v-for="(extra, index) in item.roExtra"
                              :key="'E' + index"
                            >
                              <td
                                v-if="item.roExtra.length > 0"
                                class="p-0"
                                colspan="2"
                              >
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <td class="style-td"><b>ค่าอื่นๆ</b></td>
                                      <td class="style-td">
                                        <b>ราคา/หน่วย</b>
                                      </td>
                                      <td class="style-td"><b>ราคารวม</b></td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td">
                                        <b>รวมค่าอื่นๆ</b>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td class="style-td">
                                        <li>
                                          {{ extra.nameTh
                                          }}<b
                                            >&nbsp;X&nbsp;{{ extra.amount }}</b
                                          >
                                        </li>
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            extra.pricePerOne
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            extra.totalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{ extra.dcPer }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            extra.netPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.extraNetPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" class="p-0">
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr class="table-danger">
                                      <td class="style-td">
                                        <b>รวมทั้งหมด</b>
                                      </td>
                                      <td class="style-td"><b>ส่วนลด(%)</b></td>
                                      <td class="style-td"><b>สุทธิ</b></td>
                                      <td class="style-td"><b>ภาษี(7%)</b></td>
                                      <td class="style-td"><b>สุทธิรวม</b></td>
                                      <td class="style-td">
                                        <b>หัก ณ ที่จ่าย</b>
                                      </td>
                                      <td class="style-td">
                                        <b>ยอดการชำระ</b>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.totalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(item.dcPer).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(item.netPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(item.vatPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.grandTotalPrice
                                          ).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(item.whtPrice).toLocaleString()
                                        }}
                                      </td>
                                      <td class="text-end style-td">
                                        {{
                                          Number(
                                            item.paymentAmount
                                          ).toLocaleString()
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- <tr
                      v-for="(item, index) in data.roLists"
                      :key="'B' + index"
                    >
                      <td></td>
                      <td>
                        
                        <b>{{ item.roCode }}</b>

                        <hr v-if="item.roPart.length > 0" />
                        <b v-if="item.roPart.length > 0">ค่าอะไหล่</b>
                        <div
                          class="font-size-13"
                          v-for="(part, index) in item.roPart"
                          :key="'P' + index"
                        >
                          <li>
                            {{ part.nameTh }}&nbsp;X&nbsp;<b>{{
                              part.amount
                            }}</b>
                          </li>
                        </div>
                        <hr v-if="item.roWage.length > 0" />
                        <b v-if="item.roWage.length > 0">ค่าแรง</b>
                        <div
                          class="font-size-13"
                          v-for="(wage, index) in item.roWage"
                          :key="'W' + index"
                        >
                          <li>
                            {{ wage.nameTh }}&nbsp;X&nbsp;<b>{{
                              wage.amount
                            }}</b>
                          </li>
                        </div>
                        <hr v-if="item.roLiquid.length > 0" />

                        <b v-if="item.roLiquid.length > 0">ค่าของเหลว</b>
                        <div
                          class="font-size-13"
                          v-for="(liquid, index) in item.roLiquid"
                          :key="'L' + index"
                        >
                          <li>
                            {{ liquid.nameTh }}&nbsp;X&nbsp;<b>{{
                              liquid.amount
                            }}</b>
                          </li>
                        </div>
                        <hr v-if="item.roOs.length > 0" />
                        <b v-if="item.roOs.length > 0">ค่างานนอก</b>
                        <div
                          class="font-size-13"
                          v-for="(os, index) in item.roOs"
                          :key="'O' + index"
                        >
                          <li>
                            {{ os.nameTh }}&nbsp;X&nbsp;<b>{{ os.amount }}</b>
                          </li>
                        </div>
                        <hr v-if="item.roExtra.length > 0" />

                        <b v-if="item.roExtra.length > 0">ค่าอื่นๆ</b>
                        <div
                          class="font-size-13"
                          v-for="(extra, index) in item.roExtra"
                          :key="'E' + index"
                        >
                          <li>
                            {{ extra.nameTh }}&nbsp;X&nbsp;<b>{{
                              extra.amount
                            }}</b>
                          </li>
                        </div>
                      
                      </td>
                      <td>
                        แจ้งซ่อมวันที่:
                        {{ change(item.roDate) }}
                        &nbsp;&nbsp;&nbsp;&nbsp;ลูกค้านัดหมาย:&nbsp;&nbsp;{{
                          checkIsbooking(item.isBooking)
                        }}

                        ล้างรถ:&nbsp;&nbsp;{{
                          checkIswash(item.isWash)
                        }}
                        สถานะ:&nbsp;&nbsp;{{ checkIswaiting(item.isWaiting) }}

                        <hr v-if="item.roPart.length > 0" />
                        <div class="row" v-if="item.roPart.length > 0">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคา/หน่วย</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(part, index) in item.roPart"
                              :key="'P' + index"
                            >
                              {{ Number(part.pricePerOne).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคารวม</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(part, index) in item.roPart"
                              :key="'P' + index"
                            >
                              {{ Number(part.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(part, index) in item.roPart"
                              :key="'P' + index"
                            >
                              {{ part.dcPer }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(part, index) in item.roPart"
                              :key="'P' + index"
                            >
                              {{ Number(part.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>รวมค่าอะไหล่</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.partNetPrice).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                        <hr v-if="item.roWage.length > 0" />
                        <div class="row" v-if="item.roWage.length > 0">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคา/หน่วย</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(wage, index) in item.roWage"
                              :key="'W' + index"
                            >
                              {{ Number(wage.pricePerOne).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคารวม</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(wage, index) in item.roWage"
                              :key="'W' + index"
                            >
                              {{ Number(wage.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(wage, index) in item.roWage"
                              :key="'W' + index"
                            >
                              {{ wage.dcPer }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(wage, index) in item.roWage"
                              :key="'W' + index"
                            >
                              {{ Number(wage.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>รวมค่าแรง</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.wageNetPrice).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                        <hr v-if="item.roLiquid.length > 0" />
                        <div class="row" v-if="item.roLiquid.length > 0">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคา/หน่วย</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(liquid, index) in item.roLiquid"
                              :key="'L' + index"
                            >
                              {{ Number(liquid.pricePerOne).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคารวม</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(liquid, index) in item.roLiquid"
                              :key="'L' + index"
                            >
                              {{ Number(liquid.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(liquid, index) in item.roLiquid"
                              :key="'L' + index"
                            >
                              {{ liquid.dcPer }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(liquid, index) in item.roLiquid"
                              :key="'L' + index"
                            >
                              {{ Number(liquid.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>รวมค่าของเหลว</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.liquidNetPrice).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                        <hr v-if="item.roOs.length > 0" />
                        <div class="row" v-if="item.roOs.length > 0">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคา/หน่วย</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(os, index) in item.roOs"
                              :key="'O' + index"
                            >
                              {{ Number(os.pricePerOne).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคารวม</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(os, index) in item.roOs"
                              :key="'O' + index"
                            >
                              {{ Number(os.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(os, index) in item.roOs"
                              :key="'O' + index"
                            >
                              {{ os.dcPer }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(os, index) in item.roOs"
                              :key="'O' + index"
                            >
                              {{ Number(os.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>รวมค่างานนอก</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.osNetPrice).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                        <hr v-if="item.roExtra.length > 0" />
                        <div class="row" v-if="item.roExtra.length > 0">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคา/หน่วย</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(extra, index) in item.roExtra"
                              :key="'E' + index"
                            >
                              {{ Number(extra.pricePerOne).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ราคารวม</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(extra, index) in item.roExtra"
                              :key="'E' + index"
                            >
                              {{ Number(extra.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(extra, index) in item.roExtra"
                              :key="'E' + index"
                            >
                              {{ extra.dcPer }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p
                              class="mb-0 font-size-13 text-end"
                              v-for="(extra, index) in item.roExtra"
                              :key="'E' + index"
                            >
                              {{ Number(extra.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>รวมค่าอื่นๆ</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.extraNetPrice).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                        <hr
                          style="
                            height: 1px;
                            border-width: 0;
                            color: black;
                            background-color: black;
                          "
                        />
                        <div class="row">
                          <div class="col-md-2">
                            <p class="mb-0 text-end"><b>รวมทั้งหมด</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.totalPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>ส่วนลด(%)</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.dcPer).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-4">
                            <p class="mb-0 text-end"><b>สุทธิ</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.netPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>ภาษี(7%)</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.vatPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-2"></div>
                          <div class="col-md-3">
                            <p class="mb-0 text-end"><b>สุทธิรวม</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{
                                Number(item.grandTotalPrice).toLocaleString()
                              }}
                            </p>
                          </div>
                          <div class="col-md-4 float-end">
                            <p class="mb-0 text-end"><b>หัก ณ ที่จ่าย</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.whtPrice).toLocaleString() }}
                            </p>
                          </div>
                          <div class="col-md-3 float-end">
                            <p class="mb-0 text-end"><b>ยอดการชำระ</b></p>
                            <p class="mb-0 font-size-13 text-end">
                              {{ Number(item.paymentAmount).toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td colspan="3">
                        เลขไมล์:&nbsp;&nbsp;{{ item.mileNo }}<br />
                        หมายเหตุ:&nbsp;&nbsp;{{ item.node }}
                       
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

